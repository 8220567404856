
/* ============================================ backend ========================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
/* ::selection {
    color: white;
    background: #002173;
  } */

body{
    background-color: #fff!important;
}
span,p,a {
    font-family: 'Rubik', sans-serif!important;
}

.custom_datatable_head{
    font-family: 'Rubik', sans-serif;
    background: #f3f5f9
}
.custom_datatable td, .custom_datatable td span{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color:#2e384d;
}
.custom_datatable.table-striped tbody tr:nth-of-type(odd) {
    background-color: #eff3f6;
}
.custom_datatable.table-hover tbody tr:hover {
    background-color: #eff3f6;
    transform: scale(1.0055);
    font-weight: 500;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
}

.custom_datatable.table-hover tbody tr.expanded_tr:hover {
    transform: scale(1)!important;
    box-shadow: none!important;
    font-weight: normal!important;
}
.expanded_tr{
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
}
.expanded_tr div, .expanded_tr span{
   font-size: 12px!important;
}

.custom_datatable td.total{
    font-weight: 500;
}
.custom_datatable td span.text-success{
    color:#1bc5bd!important;
}
.selected_stones, .selected_stones span{
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #2e384d;
}
.selected_diamonds_status{
    font-family: 'Rubik', sans-serif;
    font-size: 11px;
    font-weight: normal;
    color: #8798ad;
}
.page_size{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    color: #2e384d;
}
.custom_hr{
    color:#c3c3c3;
}
.table_page_length{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500!important;
    font-style: italic;
    color: #8798ad!important;
}

.W_diamond {
    border-radius: 50%;
    background-color: #002173;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.F_diamond {
    border-radius: 50%;
    background-color: #bb314c;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.L_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.LF_diamond {
    border-radius: 50%;
    background-color: #f0b33f;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.P_diamond {
    border-radius: 50%;
    background-color: #7CCDF2;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}

.flagIco{
    height: 16px!important;
    border-radius: 5px;
}

.tooltip-inner {
    text-transform: capitalize;
}
.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check > .selectBox {
    cursor: pointer;
    padding: 8px;
    margin: 1.5px;
    text-align: center;
    background-color: #00c89710;
    border: 1px solid #00c89715;
    overflow: hidden;
}
.theme2 .search_btn_check .shapeBox.selectBox{
    width: 42px;
    height: 42px;
}
.theme2 .search_btn_check > .selectBox {
    cursor: pointer;
    /* padding: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    text-align: center;
    border-radius: 5px;
    background-color: #0037bf10;
    border: 1px solid #0037bf15;
    overflow: hidden;
    transition: all .3s ease-in-out;
}
.theme2 .selectBox:hover {
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    transform: scale(1.07);
}

.theme2 .search_btn_check .wColorBox.selectBox,.theme3 .search_btn_check .wColorBox.selectBox{
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wClarityBox.selectBox,.theme3 .search_btn_check .wClarityBox.selectBox{
    width: 40px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wFluorBox.selectBox,.theme3 .search_btn_check .wFluorBox.selectBox{
    width: 105px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .wLabBox.selectBox,.theme3 .search_btn_check .wLabBox.selectBox{
    width: 84px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme2 .search_btn_check .fancyBox.selectBox , .theme3 .search_btn_check .fancyBox.selectBox{
    width: 115px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.theme3 .search_btn_check .shapeBox.selectBox{
    width: 70px;
    height: 70px;
}
.theme3 .natTab .search_btn_check > .selectBox ,.theme3 .labTab .search_btn_check > .selectBox{
    background-color: #00217300;
    border: 1px solid #00217300;
}
.theme3 .natTab .search_btn_check > input:checked ~ .selectBox,.theme3 .labTab .search_btn_check > input:checked ~ .selectBox{
    border-radius: 8px;
}
.theme3 .selectBox:hover {
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    border-radius: 8px;
    /* transform: scale(1.07); */
}
.theme3 .search_btn_check .shapeBox.selectBox span {
    font-size: 9px;
    padding-top: 7px;
    width: 52px;
}
.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}
.search_btn_check .shapeBox.selectBox{
    width: 85px;
    height: 70px;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #00c897;
}
.selectBox:hover {
    border:1px solid #00c897;
}
.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}
.search_btn_check > .selectBox svg{
    height: 36px;
    width: auto;
}
.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #00c897!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}
.accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
    right: 19px;
    position: absolute;
}
.search_accordion .card {
    border: none!important;
}
.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}
.expandedDetails{
    height: 455px;
    min-width: 1700px;
    background-color: #fbfbfb!important;
}
/* .custom_datatable{
    min-width: 1850px;
} */
.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 40px;
    width: 40px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 35px;
    margin: 0 auto;
    object-fit: cover;
}
.img_box{
    margin-left:10px ;
    height: 210px;
    background-color: #EBEDF3;
    text-align: center;
}
.img_box img{
    height: 210px;
    margin: 0 auto;
    object-fit: cover;
}

.product_detail .img_box{
    margin-left:10px ;
    width: 470px;
    height: 400px;
    background-color: #EBEDF3;
    text-align: center;
}
.product_detail .img_box img{
    height: 400px;
    margin: 0 auto;
    object-fit: cover;
}
@media (max-width: 768px) {
    .product_detail .img_box{
        margin-left:10px ;
        width: 470px;
        height: auto;
        background-color: #EBEDF3;
        text-align: center;
    }
    .product_detail .img_box img{
        width: 400px;
        height: auto;
        margin: 0 auto;
        object-fit: cover;
    }
    li.img_thumb{
        display: inline-block;
        margin-left: 5px;
    }
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.minHeight{
    min-height: 49px!important;
}

.gridData.card{
    border-radius: 0;
}
.gridData.card:hover {
    z-index: 0;
    box-shadow: 0px 8px 8px -10px rgb(0 0 0 / 68%);
    transition: all .3s ease-in-out;
    transform: scale(1.04);
    border-radius: 8px;
}

.gridData.card .img-fluid {
    height: 210px;
    width: auto;
    object-fit: cover;
}
.table-hover tbody tr.expanded_tr:hover{
    background-color: #fff!important;
}
.table-hover tbody tr.expanded_tr:hover th{
    background-color: #f3f5f9;
}
.sh_expand{
    margin:15px;
}
.sh_expand:hover{
    background-color: #fff;
}

.sh_expand:hover th{
    background-color: #f3f5f9;
}

.table-hover td .sh_expand tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
}

/* .table-hover tbody tr:hover {
    background-color: rgba(54,153,255,.12)!important;
} */

.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}



.card .MuiPaper-elevation1{
    box-shadow: none!important;
}
.btn-seprator{
    z-index: 2;
    background-color: #fff;
    position: relative;
    display: inline-block;
    padding: 0px 10px;
}
.btn-seprator{
    border-top: 1px solid #00000050;
    display: block;
}
.theme2 .btn-seprator{
    border-top: 1px solid #00000000;
    display: inline-block;
}
.custom-seprator{
    border-top: 1px solid #3699FF;
    position: absolute;
    top: 16px;
    left: 25%;
    width: 50%;
    z-index: 1;
}
.advanceFilterBtn{
    border-bottom-left-radius: 0.8rem!important;
    border-bottom-right-radius: 0.8rem!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}
.search-result .MuiTab-root {
    min-width: auto!important;
    min-height: auto!important;
    padding: 10px;
}
.search-result .MuiTabs-root {
    min-height: auto!important;
}
.search-result .MuiTabs-indicator {
    display: none!important;
}
.search-result-list .MuiTypography-root{
    padding: 0px!important;
}
.select_block {
    position: relative;
    margin-left: 800px;
}
.select_block_border{
    align-items: center;
    display: flex;
}
.custom-result-box{
    color: #002173!important;
    font-size: 1.08rem;
    background-color: #3699ff14 !important;
    font-weight: 500 !important;
    padding: 0.45rem 1.25rem !important;
    border-color: #3699FF !important;
    border: 1px solid #EBEDF3 !important;
    margin-left: 10px;
    display: inline-block;
}

.hover-card .slide {
    transition: 0.5s;
}

.hover-card .slide.slide1 {
    background-color: #fff;
    z-index: 1;
    transition: 0.7s;
    transform: translateY(0px);
}
  
.hover-card:hover .slide.slide1 {
    transform: translateY(0px);
}

.hover-card .slide.slide2 {
    box-sizing: border-box;
    transition: 0.8s;
    transform: translateY(-50px);
}
  
.hover-card:hover .slide.slide2 {
    transform: translateY(0);
  
}


 .MultiLingual .MuiOutlinedInput-inputMarginDense {
    padding-top: 6.5px;
    padding-bottom: 6.5px;
    color: #3699FF!important;
    text-transform: uppercase;
    font-weight: 600;
}
.MultiLingual .MuiOutlinedInput-notchedOutline {
   border-color: #3699FF!important;
   border-radius: 0;
}

.header-fixed .wrapper {
    padding-top: 0px!important;
}
.stone-detail-carat-box {
    border-radius: 10px;
    background-color: #00217305;
    background: rgb(253, 253, 253);
    background: linear-gradient(0deg, #00c8966c 0%, #00c8960c 100%);
    padding: 20px 25px;
    margin: 0 auto;
    height: 170px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
@media (max-width: 768px) {
    .stone-detail-carat-box {
        height: 130px;
    }
}
.stone-detail-carat-box .point {
    position: relative;
    left: 0;
    width: 143px;
    margin-left: -70px;
}
.stone-detail-carat-box .point .arrow {
    border-width: 15px 10px 0px 10px;
    display: block;
    border-color: transparent;
    border-style: dashed;
    border-top-color: #002173;
    border-top-style: solid;
    position: absolute;
    left: 50%;
    top: 15%;
    margin-left: -10px;
}
.form-control[readonly] {
    background-color: #e9ecef!important;
}
.parent-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* This will make the container full height of the viewport */
  }
.fiter-card{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px!important;
    border: 1px solid #00000010;
  }
.table th#total_price,.table th#dollar_carat{
    text-align: right!important;
    padding-right: 13px;
}